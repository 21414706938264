<template>
  <z-button
    data-test-id="download-btn"
    variant="secondary"
    fit="auto"
    size="md"
    :disabled="loading"
    :loading="loading"
    @click="downloadCSV"
  >
    <font-awesome-icon icon="download" />
  </z-button>
</template>

<script>
import DateFilters from "@/constants/filters/date";
import CSVContainers from "@/services/csvContainers";
import fileDownload from "@/fileDownload";
import { endOfDay, startOfDay } from "date-fns";
import { formatToISO, dateRange } from "@zubut/common/src/utils/time";

export default {
  name: "ClientsReportDownload",

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    dateFilter: {
      type: Number,
      default: null
    },
    date: {
      type: Object,
      default: null
    },
    search: {
      type: String,
      default: null
    },
    status: {
      type: Number,
      default: null
    },
    type: {
      type: Number,
      default: null
    },
    city: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      dateInner: {}
    };
  },

  methods: {
    downloadCSV() {
      this.updateLoading(true);
      const where = this.makeFilter();
      CSVContainers.generateUsers(where)
        .then(
          res => fileDownload(res, "users.csv", "application/octet-stream"),
          err => this.$emit("error", err)
        )
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.updateLoading(false);
        });
    },
    makeFilter() {
      let where = {};
      try {
        this.dateInner.from = this.date?.from || null;
        this.dateInner.until = this.date?.until || null;
        if (this.search) {
          where.like = this.search;
        }

        if (this.status != null) {
          where.status = parseInt(this.status, 10);
        }

        if (this.dateFilter !== DateFilters.NUM_CUSTOM) {
          const newDate = dateRange(this.dateFilter);
          if (newDate.from != null && newDate.until != null) {
            newDate.from = formatToISO(newDate.from);
            newDate.until = formatToISO(newDate.until);
          }
          this.dateInner = newDate;
        }

        if (this.dateInner.from != null && this.dateInner.until != null) {
          where.range = {
            from: formatToISO(startOfDay(new Date(this.dateInner.from))),
            until: formatToISO(endOfDay(new Date(this.dateInner.until)))
          };
        }
        if (this.type != null) {
          where.type = this.type;
        }
        if (this.city != null) {
          where.cityId = this.city;
        }
      } catch (err) {
        this.$captureError(err);
      }

      return where;
    },
    updateLoading(value) {
      this.$emit("update:loading", value);
    }
  }
};
</script>
