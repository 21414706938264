<template lang="html">
  <div>
    <b-card>
      <div class="p-3">
        <b-row>
          <b-col
            class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center"
          >
            <span class="card-title mb-md-0">
              Clientes Registrados
            </span>
            <buttons-layout>
              <z-dropdown-cities
                v-model="selectedCity"
                class="order-2 order-md-0"
              />
              <z-button
                class="order-0 order-md-2"
                @click="$router.push({ name: 'clientCreate', query: {} })"
              >
                Crear cliente
              </z-button>
              <clients-report-download
                class="order-1 order-md-1"
                :loading.sync="loadingCSV"
                :date-filter="dateFilter"
                :date="date"
                :type="type"
                :search="searchText"
                :status="status"
                :city="selectedCity"
                @error="handleReportError"
              />
            </buttons-layout>
          </b-col>
        </b-row>
        <b-row class="filter-container mt-5 mt-md-3">
          <b-col md="4" lg="3" xl="3">
            <div class="search-filter d-flex flex-row align-items-center pl-3">
              <font-awesome-icon class="icon" icon="search" />
              <b-form-input
                v-model="searchText"
                class="flex-fill"
                placeholder="Busca por ID, nombre o correo"
                size="sm"
                type="search"
              />
            </div>
          </b-col>
          <b-col sm="12" md="2" class="mt-3 mt-md-0">
            <z-dropdown
              v-model="status"
              name="Estado"
              :options="statusOptions"
            />
          </b-col>
          <b-col sm="12" md="2" class="mt-3 mt-md-0">
            <z-dropdown
              id="dropdown-date"
              v-model="dateFilter"
              :options="datesOptions"
              name="Fecha"
            />
          </b-col>
          <b-col v-show="showDatePicker" sm="12" md="2" class="mt-3 mt-md-0">
            <z-date-picker
              v-model="date.from"
              calendar-class="date-picker"
              :disabled-dates="disableDatesFrom"
              input-class="form-control bg-white w-100"
              placeholder="Desde"
            />
          </b-col>
          <b-col v-show="showDatePicker" sm="12" md="2" class="mt-3 mt-md-0">
            <z-date-picker
              v-model="date.until"
              calendar-class="date-picker"
              :disabled-dates="disableDatesUntil"
              input-class="form-control bg-white w-100"
              placeholder="Hasta"
            />
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <quick-message
              class="mb-3"
              with-icon
              :show.sync="hasError"
              :message="message"
              type="error"
            />
            <z-table
              responsive="lg"
              hover
              :items="users"
              :per-page="perPage"
              :fields="fields"
              :busy="isLoading"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              @row-clicked="viewUser"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <loading-spinner />
                </div>
              </template>
              <template v-slot:empty>
                <p class="text-center py-5 my-5 font-weight-semi-bold">
                  {{
                    "No se encontraron usuarios registrados que coincidan con el filtro"
                  }}
                </p>
              </template>
              <template v-slot:head(type)>
                <div>
                  <div class="d-lg-none">
                    Tipo
                  </div>
                  <z-table-header-dropdown
                    id="dropdown-user-type"
                    v-model="type"
                    class="d-none d-lg-block"
                    :options="typeOptions"
                    name="Tipo"
                  />
                </div>
              </template>
              <template v-slot:head(status)>
                <div>
                  <div class="d-lg-none">
                    Estado
                  </div>
                  <z-table-header-dropdown
                    id="dropdown-user-status"
                    v-model="status"
                    class="d-none d-lg-block"
                    :options="statusOptions"
                    name="Estado"
                  />
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <status-client :status="data.value" />
              </template>
              <template v-slot:cell(type)="{ item: user }">
                <span>
                  {{ getUserType(user) }}
                </span>
              </template>
            </z-table>
            <z-table-pagination
              :total-rows="totalRows"
              :per-page.sync="perPage"
              :current-page.sync="currentPage"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
    <router-view></router-view>
  </div>
</template>

<script>
import ButtonsLayout from "@/app/layouts/ButtonsLayout.vue";
import DateFilters from "@/constants/filters/date";
import StatusClient from "@/app/components/StatusClient";
import ZDropdownCities from "@/app/components/ZDropdownCities.vue";
import filtering from "@/mixins/filtering";
import { dateRange, formatToISO } from "@zubut/common/src/utils/time";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZDatePicker from "@zubut/common/src/components/ZDatePicker";
import ZTableHeaderDropdown from "@zubut/common/src/components/ZTableHeaderDropdown";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import ClientStatus from "@/constants/clients/status";
import ClientType from "@zubut/common/src/constants/clients/type";
import ClientAccess from "@/constants/access/clients";
import { formatISO, endOfDay, startOfDay } from "date-fns";
import ClientsReportDownload from "./ClientsReportDownload";

export default {
  name: "Clients",

  components: {
    ButtonsLayout,
    ClientsReportDownload,
    StatusClient,
    ZDropdownCities,
    ZDropdown,
    ZTable,
    ZTablePagination,
    ZTableHeaderDropdown,
    ZDatePicker
  },

  mixins: [filtering("clients")],

  data() {
    return {
      isLoading: false,
      sortBy: "lastLogin",
      sortDesc: true,
      filteredUsers: [],
      fields: [
        { key: "name", label: "Nombre" },
        { key: "email", label: "Email" },
        { key: "phone", label: "Teléfono" },
        { key: "type", label: "Tipo" },
        { key: "city", label: "Ciudad" },
        { key: "status", label: "Estado" }
      ],
      statusOptions: [
        { value: null, text: "Todos" },
        { value: ClientStatus.NUM_NEW, text: ClientStatus.NEW },
        { value: ClientStatus.NUM_ACTIVE, text: ClientStatus.ACTIVE },
        { value: ClientStatus.NUM_BLOCKED, text: ClientStatus.BLOCKED }
      ],
      typeOptions: [{ value: null, text: "Todos" }, ...ClientType.options],
      hasError: false,
      message: "",
      date: { from: null, until: null },
      loadingCSV: false
    };
  },

  computed: {
    selectedCity: {
      get() {
        return this.$store.getters[`cities/getCity`];
      },
      set(val) {
        this.setFilter("city", val);
      }
    },
    status: {
      get() {
        return this.filters.status ?? null;
      },
      set(val) {
        this.setFilter("status", val);
      }
    },

    type: {
      get() {
        return this.filters.type ?? null;
      },
      set(val) {
        this.setFilter("type", val);
      }
    },

    searchText: {
      get() {
        return this.filters.search ?? "";
      },
      set(val) {
        this.setFilter("search", val);
      }
    },

    dateFilter: {
      get() {
        return this.filters.date;
      },
      set(value) {
        this.setFilter("date", value);
      }
    },

    datesOptions() {
      return [{ value: null, text: "Todos" }, ...DateFilters.simplifiedOptions];
    },

    showDatePicker() {
      return this.dateFilter === DateFilters.NUM_CUSTOM;
    },

    disableDatesFrom() {
      let date;
      if (this.date.until != null) {
        date = new Date(this.date.until);
      } else {
        date = new Date();
      }
      return { from: date };
    },

    disableDatesUntil() {
      let date;
      if (this.date.from != null) {
        date = new Date(this.date.from);
      } else {
        date = new Date();
      }
      return { to: date };
    },

    users() {
      return this.$store.getters["user/listingUsers"];
    }
  },

  watch: {
    date: {
      deep: true,
      handler() {
        if (this.dateFilter === DateFilters.NUM_CUSTOM) {
          this.getListingUsers();
        }
      }
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (to.name === "clientCreate" || from.name === "clientCreate") {
      this.shouldCallListener = false;
    } else {
      this.shouldCallListener = true;
    }

    next();
  },

  created() {
    this.registerListeners(this.getListingUsers);
    this.getListingUsers();
  },

  methods: {
    buildRequest() {
      const filter = {
        ...this.pagination,
        order: `${this.sortBy} DESC`
      };

      const where = { like: this.searchText };

      try {
        if (this.filters.status != null) {
          where.status = this.filters.status;
        }

        if (this.filters.type != null) {
          where.type = this.filters.type;
        }

        if (this.dateFilter !== DateFilters.NUM_CUSTOM) {
          const newDate = dateRange(this.dateFilter);
          if (newDate.from != null && newDate.until != null) {
            newDate.from = formatToISO(newDate.from);
            newDate.until = formatToISO(newDate.until);
          }
          this.date = newDate;
        }

        if (this.date.from != null && this.date.until != null) {
          where.range = {
            from: formatISO(startOfDay(new Date(this.date.from))),
            until: formatISO(endOfDay(new Date(this.date.until)))
          };
        }

        if (this.selectedCity) {
          where.cityId = this.selectedCity;
        }
      } catch (err) {
        this.$captureError(err);
      }

      return { filter, where };
    },

    getListingUsers() {
      this.isLoading = true;
      this.$store
        .dispatch("user/getListingUsers", this.buildRequest())
        .then(({ meta }) => {
          if (meta.skip === 0) {
            this.totalRows = meta.count;
          }
        })
        .catch(err => {
          this.hasError = true;
          this.message = err.message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getUserType(user) {
      return ClientType.get[user.type];
    },

    handleReportError(err) {
      if (err.statusCode === 404) {
        this.hasError = true;
        this.message = "Error al exportar datos, no hay datos disponibles.";
      } else {
        this.message = err.message;
        this.$captureError(err);
      }
    },

    viewUser(item) {
      const id = item.id;
      if (this.$can(ClientAccess.READ_CLIENT_DETAIL, ClientAccess.moduleName)) {
        this.$router.push({ name: "clientDetail", params: { id } });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-filter {
  cursor: text;
  background-color: $white;
  border: 1px solid $gainsboro;
  border-radius: 0.25rem;

  .icon {
    color: $nobel;
  }

  input {
    cursor: text;
    color: $nero;
    min-height: 32px;
    font-size: 12px;
    border-color: transparent;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.spinner {
  max-height: 30px;
  padding-top: 5px;
}
</style>
